import styles from '../styles/components/ContactUs_Section.module.sass';
import MainPage_ContactUs_TitleLine_SVG from './svg/lines/MainPage_ContactUs_TitleLine_SVG';
import Location_SVG from './svg/icons/Location_SVG';
import Phone_SVG from './svg/icons/Phone_SVG';
import 'swiper/swiper.min.css';
import {Swiper, SwiperSlide} from 'swiper/react';
import BaseLogo_SVG from './svg/logos/BaseLogo_SVG';
import AuditLogo_SVG from './svg/logos/AuditLogo_SVG';
import ConseilLogo_SVG from './svg/logos/ConseilLogo_SVG';
import SocialLogo_SVG from './svg/logos/SocialLogo_SVG';
import cn from 'classnames';
import {FaExclamationCircle} from 'react-icons/fa';
import Cleave from 'cleave.js/react';
import React, {forwardRef, useRef, useState} from 'react';
import 'cleave.js/dist/addons/cleave-phone.fr';
import SendButton from './SendButton';
import Link from 'next/link';
import {GoogleMap, Marker, withGoogleMap, withScriptjs} from 'react-google-maps';

const InterestedBy = forwardRef(({
                                     children,
                                     name,
                                     checked = false,
                                 }, ref) => {

    return (
        <label>
            <input
                type="radio"
                id={name}
                name={'interested_by'}
                value={name}
                ref={ref}
                defaultChecked={checked}
                aria-label={name}
            />
            <div className={styles.content}>
                {children}
            </div>
        </label>
    );
});
InterestedBy.displayName = 'InterestedBy';

const ContactUs_Section = () => {

    //region References
    // Form references
    // Interested in
    const interestedInIncomeRef = useRef();
    const interestedInIncomeAuditRef = useRef();
    const interestedInIncomeConseilRef = useRef();
    const interestedInIncomeSocialRef = useRef();
    const nameRef = useRef();
    const mailRef = useRef();
    const phoneRef = useRef();
    const messageRef = useRef();
    //endregion

    //region States
    const [sendingState, setSendingState] = useState('none');

    const [nameError, setNameError] = useState(false);
    const [mailError, setMailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [messageError, setMessageError] = useState(false);
    //endregion

    //region Handlers
    const sendContactEmail = () => {
        setSendingState('sending');

        //region Get data
        let formData = [];

        //region Get fields values
        // Interested in
        let interestedIn;
        if (interestedInIncomeRef.current?.checked) {
            interestedIn = 'income';
        }
        if (interestedInIncomeAuditRef.current?.checked) {
            interestedIn = 'income Audit';
        }
        if (interestedInIncomeConseilRef.current?.checked) {
            interestedIn = 'income Conseil';
        }
        if (interestedInIncomeSocialRef.current?.checked) {
            interestedIn = 'income Social';
        }

        // Name
        const nameValue = nameRef.current?.value
            ?? nameRef.current?.element?.value
            ?? '';
        // Mail
        const mailValue = mailRef.current?.value
            ?? mailRef.current?.element?.value
            ?? '';
        // Phone
        const phoneValue = phoneRef.current?.value
            ?? phoneRef.current?.element?.value
            ?? '';
        // Message
        const messageValue = messageRef.current?.value
            ?? messageRef.current?.element?.value
            ?? '';
        //endregion

        // Reset errors
        setNameError(false);
        setMailError(false);
        setPhoneError(false);
        setMessageError(false);

        let isValid = true;
        if (!nameValue) {
            isValid = false;
            setNameError(true);
        }

        var mailFormat = /^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+)@([a-zA-Z0-9-]+)(?:\.[a-zA-Z0-9-]+)*$/;
        if (!mailValue
            || !mailValue.match(mailFormat)) {
            isValid = false;
            setMailError(true);
        }
        if (!phoneValue) {
            isValid = false;
            setPhoneError(true);
        }
        if (!messageValue) {
            isValid = false;
            setMessageError(true);
        }

        if (isValid) {

            //region Add into form data
            // Interested in
            formData.push({
                name: 'Intéressé par',
                value: interestedIn,
            });

            // Name
            formData.push({
                name: 'Nom',
                value: nameValue,
            });

            // Mail
            formData.push({
                name: 'Mail',
                value: mailValue,
            });

            // Phone
            formData.push({
                name: 'Téléphone',
                value: phoneValue,
            });

            // Message
            formData.push({
                name: 'Message',
                value: messageValue,
            });
            //endregion

            fetch(
                '/api/contact',
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(formData),
                },
            )
                .then(res => {
                    // If success
                    if (res.status
                        === 200) {
                        setSendingState('sent');
                    } else {
                        setSendingState('none');
                    }
                });
        } else {
            setSendingState('none');
        }
    };
    //endregion

    return (
        <div className={cn(styles.content)}>
            <div className={styles.map}>
                <MapWrapped
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&callback=Function.prototype`}
                    loadingElement={<div style={{height: `100%`}}/>}
                    containerElement={<div style={{height: `400px`}}/>}
                    mapElement={<div style={{height: `100%`}}/>}
                />
            </div>

            <div className={styles.right}>
                <div className={styles.title}>
                    <MainPage_ContactUs_TitleLine_SVG className={styles.line}/>
                    <h3>Rencontrer nos clients est une étape essentielle dans la construction de nos relations de
                        travail.</h3>
                </div>

                {/* Contact information */}
                <div className={styles.contact_information}>
                    {/* Adresse */}
                    <div className={styles.location}>
                        <Location_SVG className={styles.icon}/>
                        <div className={styles.text}>
                            <span className={styles.value}>59 avenue du Brézet<br/> 63100 Clermont-Ferrand</span>
                            <Link
                                scroll={false}
                                href="https://maps.app.goo.gl/rjzx6akwe7PFjVax9"
                                className={styles.link}
                                target={'_blank'}
                            >
                                <span className={styles.text}>Montrer l&apos;itinéraire</span>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24.917"
                                    height="11.683"
                                    viewBox="0 0 24.917 11.683"
                                >
                                    <g
                                        id="Group_105"
                                        data-name="Group 105"
                                        transform="translate(39.236 -7.159) rotate(90)"
                                    >
                                        <line
                                            id="Line_9"
                                            data-name="Line 9"
                                            y1="23.68"
                                            transform="translate(13.001 15.557)"
                                            fill="none"
                                            stroke="#d36f3b"
                                            strokeMiterlimit="10"
                                            strokeWidth="1.75"
                                        />
                                        <path
                                            id="Path_141"
                                            data-name="Path 141"
                                            d="M7.778,20.78,13,15.557l5.223,5.223"
                                            transform="translate(0 0)"
                                            fill="none"
                                            stroke="#d36f3b"
                                            strokeMiterlimit="10"
                                            strokeWidth="1.75"
                                        />
                                    </g>
                                </svg>
                            </Link>
                        </div>
                    </div>

                    <a
                        className={styles.phone_number}
                        href={'tel:0473341234'}
                    >
                        <Phone_SVG
                            className={styles.icon}
                            ringClassName={styles.ring}
                        />

                        <u className={styles.text}>04 73 34 12 34</u>
                    </a>
                </div>

                {/* Form */}
                <form
                    className={styles.form}
                    id={'newsletter'}
                >
                    <div className={styles.inputs}>
                        <div className={styles.interested_by}>
                            <span className={styles.title}>Je suis intéressé par :</span>

                            <div className={styles.choices}>
                                <Swiper
                                    className={styles.swiper}
                                    spaceBetween={16}
                                    slidesPerView={4}

                                    preventClicks={false}
                                    preventClicksPropagation={false}

                                    breakpoints={{
                                        [1]: {
                                            slidesPerView: 2.2,
                                            spaceBetween: 10,
                                        },
                                        [768]: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {/* income */}
                                    <SwiperSlide
                                        className={styles.swiper_slide}
                                        key={'income'}
                                    >
                                        <InterestedBy
                                            name={'income'}
                                            ref={interestedInIncomeRef}
                                            checked={true}
                                        >
                                            <BaseLogo_SVG
                                                className={styles.logo}
                                                color={''}
                                            />
                                        </InterestedBy>
                                    </SwiperSlide>

                                    {/* income AUDIT */}
                                    <SwiperSlide
                                        className={styles.swiper_slide}
                                        key={'income-audit'}
                                    >
                                        <InterestedBy
                                            name={'income-audit'}
                                            ref={interestedInIncomeAuditRef}
                                        >
                                            <AuditLogo_SVG
                                                className={styles.logo}
                                                color={''}
                                            />
                                        </InterestedBy>
                                    </SwiperSlide>

                                    {/* income CONSEIL */}
                                    <SwiperSlide
                                        className={styles.swiper_slide}
                                        key={'income-conseil'}
                                    >
                                        <InterestedBy
                                            name={'income-conseil'}
                                            ref={interestedInIncomeConseilRef}
                                        >
                                            <ConseilLogo_SVG
                                                className={styles.logo}
                                                color={''}
                                            />
                                        </InterestedBy>
                                    </SwiperSlide>

                                    {/* income SOCIAL */}
                                    <SwiperSlide
                                        className={styles.swiper_slide}
                                        key={'income-social'}
                                    >
                                        <InterestedBy
                                            name={'income-social'}
                                            ref={interestedInIncomeSocialRef}
                                        >
                                            <SocialLogo_SVG
                                                className={styles.logo}
                                                color={''}
                                            />
                                        </InterestedBy>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>

                        <label
                            className={cn(
                                styles.name,
                                {[styles.error]: nameError},
                            )}
                        >
                            <input
                                type="text"
                                required
                                placeholder={'Jacques Dupont *'}
                                ref={nameRef}
                            />
                            <span className={styles.label}>Nom Prénom</span>
                            <span className={styles.error}><span className={styles.text}>Erreur de saisie du nom et prénom</span><FaExclamationCircle
                                className={styles.icon}/></span>
                        </label>

                        <label
                            className={cn(
                                styles.mail,
                                {[styles.error]: mailError},
                            )}
                        >
                            <input
                                type="text"
                                placeholder={'mail@mail.com'}
                                ref={mailRef}
                            />
                            <span className={styles.label}>Mail</span>
                            <span className={styles.error}><span className={styles.text}>Erreur de saisie de l&apos;adresse mail</span><FaExclamationCircle
                                className={styles.icon}/></span>
                        </label>

                        <label
                            className={cn(
                                styles.phone_number,
                                {[styles.error]: phoneError},
                            )}
                        >
                            <Cleave
                                options={{
                                    phone: true,
                                    phoneRegionCode: 'fr',
                                }}
                                //												autocomplete={ 'phone' }
                                placeholder={'Téléphone'}
                                ref={phoneRef}
                            />
                            <span className={styles.label}>Téléphone</span>
                            <span className={styles.error}><span className={styles.text}>Erreur de saisie du numéro de téléphone</span><FaExclamationCircle
                                className={styles.icon}/></span>
                        </label>

                        <label
                            className={cn(
                                styles.message,
                                {[styles.error]: messageError},
                            )}
                        >
									<textarea
                                        required
                                        placeholder={'Votre message...*'}
                                        ref={messageRef}
                                    ></textarea>
                            <span className={styles.label}>Message</span>
                            <span className={styles.error}><span className={styles.text}>Erreur de saisie du message</span><FaExclamationCircle
                                className={styles.icon}/></span>
                        </label>
                    </div>

                    <div className={styles.legend}>
                        <span>*Champs obligatoire</span>
                    </div>

                    {/* Send button */}
                    <SendButton
                        sendingState={sendingState}
                        onClick={sendContactEmail}
                        className={styles.send_button}
                    />
                </form>
            </div>
        </div>
    );
};

const MapWrapped = withScriptjs(withGoogleMap(props => {

    /*const customMapStyle = [
     {
     'elementType': 'labels',
     'stylers':     [
     {
     'visibility': 'off',
     },
     {
     'color': '#f49f53',
     },
     ],
     },
     {
     'featureType': 'landscape',
     'stylers':     [
     {
     'color': '#f9ddc5',
     },
     {
     'lightness': -7,
     },
     ],
     },
     {
     'featureType': 'road',
     'stylers':     [
     {
     'color': '#813033',
     },
     {
     'lightness': 43,
     },
     ],
     },
     {
     'featureType': 'poi.business',
     'stylers':     [
     {
     'color': '#645c20',
     },
     {
     'lightness': 38,
     },
     ],
     },
     {
     'featureType': 'water',
     'stylers':     [
     {
     'color': '#1994bf',
     },
     {
     'saturation': -69,
     },
     {
     'gamma': 0.99,
     },
     {
     'lightness': 43,
     },
     ],
     },
     {
     'featureType': 'road.local',
     'elementType': 'geometry.fill',
     'stylers':     [
     {
     'color': '#f19f53',
     },
     {
     'weight': 1.3,
     },
     {
     'visibility': 'on',
     },
     {
     'lightness': 16,
     },
     ],
     },
     {
     'featureType': 'poi.business',
     },
     {
     'featureType': 'poi.park',
     'stylers':     [
     {
     'color': '#645c20',
     },
     {
     'lightness': 39,
     },
     ],
     },
     {
     'featureType': 'poi.school',
     'stylers':     [
     {
     'color': '#a95521',
     },
     {
     'lightness': 35,
     },
     ],
     },
     {},
     {
     'featureType': 'poi.medical',
     'elementType': 'geometry.fill',
     'stylers':     [
     {
     'color': '#813033',
     },
     {
     'lightness': 38,
     },
     {
     'visibility': 'off',
     },
     ],
     },
     {},
     {},
     {},
     {},
     {},
     {},
     {},
     {},
     {},
     {},
     {},
     {
     'elementType': 'labels',
     },
     {
     'featureType': 'poi.sports_complex',
     'stylers':     [
     {
     'color': '#9e5916',
     },
     {
     'lightness': 32,
     },
     ],
     },
     {},
     {
     'featureType': 'poi.government',
     'stylers':     [
     {
     'color': '#9e5916',
     },
     {
     'lightness': 46,
     },
     ],
     },
     {
     'featureType': 'transit.station',
     'stylers':     [
     {
     'visibility': 'off',
     },
     ],
     },
     {
     'featureType': 'transit.line',
     'stylers':     [
     {
     'color': '#813033',
     },
     {
     'lightness': 22,
     },
     ],
     },
     {
     'featureType': 'transit',
     'stylers':     [
     {
     'lightness': 38,
     },
     ],
     },
     {
     'featureType': 'road.local',
     'elementType': 'geometry.stroke',
     'stylers':     [
     {
     'color': '#f19f53',
     },
     {
     'lightness': -10,
     },
     ],
     },
     {},
     {},
     {},
     ]*/

    const customMapStyle = [
        {
            'featureType': 'all',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#e3dfd6',
                },
            ],
        },
        {
            'featureType': 'all',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#d3cdc3',
                },
            ],
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'saturation': 36,
                },
                {
                    'color': '#333333',
                },
                {
                    'lightness': 40,
                },
            ],
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.stroke',
            'stylers': [
                {
                    'visibility': 'on',
                },
                {
                    'color': '#ffffff',
                },
                {
                    'lightness': 16,
                },
            ],
        },
        {
            'featureType': 'all',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off',
                },
            ],
        },
        {
            'featureType': 'administrative',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#fefefe',
                },
                {
                    'lightness': 20,
                },
            ],
        },
        {
            'featureType': 'administrative',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#fefefe',
                },
                {
                    'lightness': 17,
                },
                {
                    'weight': 1.2,
                },
            ],
        },
        {
            'featureType': 'landscape',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#f1f1ec',
                },
                {
                    'lightness': '16',
                },
            ],
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'all',
            'stylers': [
                {
                    'visibility': 'on',
                },
                {
                    'saturation': '0',
                },
                {
                    'color': '#eee9e1',
                },
            ],
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry',
            'stylers': [
                {
                    'visibility': 'on',
                },
                {
                    'color': '#f1f1ec',
                },
                {
                    'lightness': '-9',
                },
            ],
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'visibility': 'on',
                },
                {
                    'color': '#f1f1ec',
                },
                {
                    'lightness': '-39',
                },
            ],
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'saturation': '0',
                },
            ],
        },
        {
            'featureType': 'road.highway',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#ffffff',
                },
                {
                    'lightness': 17,
                },
            ],
        },
        {
            'featureType': 'road.highway',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#ffffff',
                },
                {
                    'lightness': 29,
                },
                {
                    'weight': 0.2,
                },
            ],
        },
        {
            'featureType': 'road.arterial',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ffffff',
                },
                {
                    'lightness': 18,
                },
            ],
        },
        {
            'featureType': 'road.local',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ffffff',
                },
                {
                    'lightness': 16,
                },
            ],
        },
        {
            'featureType': 'transit',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#f2f2f2',
                },
                {
                    'lightness': 19,
                },
            ],
        },
        {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#e9e5de',
                },
                {
                    'lightness': 17,
                },
            ],
        },
    ];

    return (
        <div className={styles.google_map}>
            <GoogleMap
                defaultZoom={18}
                defaultCenter={{
                    lat: 45.779727570639515,
                    lng: 3.1382708592746593,
                }}

                defaultOptions={{
                    styles: customMapStyle,
                }}

                containerElement={<div
                    className={styles.container_element}
                />}
                mapElement={<div
                    className={styles.map_element}
                />}

                {...props}
            >
                <Marker
                    position={{
                        lat: 45.779727570639515,
                        lng: 3.1382708592746593,
                    }}
                    icon={{url: '/images/location.svg'}}
                >
                </Marker>
            </GoogleMap>
        </div>
    );
}));

export default ContactUs_Section;
